@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;700&display=swap');
@import 'src/colors';

html body {
  font-family: "Sora", sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
  line-height: 30px;
  color: $secondary;
}

header {
  background: #F6F3FC;
}

h1 {
  font-weight: 700;
  font-size: 60px;
  line-height: 70px;
  margin: 20px 0px;
  background: linear-gradient(to right, $primary, $primary-dark);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

h2 {
  font-weight: 700;
  font-size: 32px;

  &.large {
    font-size: 40px;
  }
}


h3 {
  font-weight: 500;
  font-size: 60px;
}

h4 {
  font-weight: 400;
}

.thin {
  font-weight: 400;
}

.left-nav {
  list-style: none;
  max-width: 400px;
  padding-left: 0px;
  cursor: pointer;
  li {
    margin-top: 30px;
    padding: 7px 15px;
    border-radius: 5px;
  }

  .active {
    background: #ffffff;
  }

  .fa-solid {
    float: right;
    margin-top: 8px;
  }
}


.section {
  padding-top: 30px;
  padding-bottom: 20px;
  min-height: 300px;

  @media (min-width: 768px) {
    padding-top: 50px;
    padding-bottom: 50px;
  }

}

.lead-section {
  padding-bottom: 0px;
  padding-top: 105px;

  @media (min-width: 768px) {
    padding-bottom: 100px;
  }
}

.figures {
  margin-top: 40px;
}

@media (min-width: 768px) {
  .figures {
    margin-top: 140px;
  }
}

.lead-bg {
  background: #F6F3FC url('/images/mountain.png');
  background-size: 1320px 748px;
  background-repeat: no-repeat;
  background-position: top;
}

.section-colored {
  background: #F6F3FC;
}

.figures-tile {
  color: $primary;

  @media (max-width: 767.98px) {
    h3 {
      font-size: 50px;
    }
  }

  @media (min-width: 768px) {
    h3 {
      float: left;
      margin-right: 10px;
    }
    span {
      float: left;
      line-height: 22px;
    }
  }


}

.card-link:hover {
  color: #fff;
}

nav {
  font-weight: 400;
}


.mt-xl {
  margin-top: 50px;
  @media (min-width: 768px) {
    margin-top: 150px;
  }


}



ul {
  list-style: none;
  padding: 0;
  margin: 0;
}


.how-card {
  padding: 30px;
  background: #00acee;
  color: #ffffff;
  border-radius: 20px;
  margin-bottom: 20px;

  &.small {
    padding: 22px 20px;
    height: 108px;
    text-align: center;


    h4 {
      margin-bottom: 0px;
      font-size: 16px;
      line-height: 22px;
    }

    @media (max-width: 767.98px) {
      padding: 22px 10px;

      h4 {
        font-size: 14px;
      }
    }
  }


  .step {
    width: 50px;
    height: 50px;
    color: #000000;
    background: #ffffff;
    border-radius: 5px;
    margin-bottom: 40px;
    font-size: 20px;
    font-weight: 400;
    padding-top: 10px;
  }

  h5 {
    font-size: 15px;
    margin-bottom: 0px;
  }


}


.movable-element {
  cursor: pointer;
  transition: transform 0.3s ease; /* Smooth transition */
}

.movable-element:hover {
  transform: translateY(-7px); /* Move up by 5 pixels */
}










///////////Timeline


/* Timeline Container */
.timeline {
  position: relative;
  padding: 20px 0;
  margin-top: 20px;
}

/* Center Line */


/* Timeline Item */
.timeline-one .timeline-item {
  position: relative;
  padding: 20px;
  width: 50%;
  z-index: 2; /* Ensures items sit above the center line */

  h5 {
    color: $primary;
    margin-top: 9px;
  }
}

/* Odd Items on Left */
.timeline-one .timeline-item:nth-child(odd) {
  float: left;
  clear: both;
  text-align: right;
  padding-right: 30px;
  border-right: 2px solid $primary;
}

/* Even Items on Right */
.timeline-one .timeline-item:nth-child(even) {
  float: right;
  clear: both;
  padding-left: 30px;

}

.timeline-one .timeline-item:nth-child(even)::before {
  content: '';
  position: absolute;
  top: 0;
  left: -2px; /* Offset the border by 1px */
  height: 100%;
  width: 2px;
  background-color: $primary;
}

/* Icon for Each Timeline Item */
.timeline-one .timeline-item .icon {
  position: absolute;
  top: 20px;
  width: 40px;
  height: 40px;
  background-color: #fff;
  border: 2px solid $primary;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $primary;
  font-size: 20px;
  z-index: 3; /* Ensures icons sit above the center line */
}

/* Position Icons Alternately */
.timeline-one .timeline-item:nth-child(odd) .icon {
  right: -20px;
}
.timeline-one .timeline-item:nth-child(even) .icon {
  left: -20px;
}


/* Steps */

.step-1 {
  background: #B496F0;
}

.step-2 {
  background: #7F5CCF;
}

.step-3 {
  background: #54309A;
}

.step-4 {
  background: #331D65;
}



/* Responsive Design */
@media (max-width: 768px) {
  .timeline-one .timeline-item {
    width: 100%;
    padding-left: 40px;
    padding-right: 40px;
    text-align: left;
  }
  .timeline-one .timeline-item .icon {
    left: 50%;
    transform: translateX(-50%);
  }
  .timeline-one .timeline-item:nth-child(odd) .icon,
  .timeline-one .timeline-item:nth-child(even) .icon {
    left: 50%;
    transform: translateX(-50%);
  }
}











///////////////////////


a {
  color: inherit;
  text-decoration: none;
}

a:hover {
  color: $primary;
}

.btn {
  border-radius: 0.25rem !important;
}
.btn-primary {
  background: $button-primary;
  border-color: $button-primary-dark;
}

button.disabled {
  background: $button-primary !important;
  border-color: $button-primary-dark !important;
}

.btn-primary:hover {
  background: $button-primary-dark;
  border-color: $button-primary-dark;
}









h5 {
  font-size: 20px
}

/*.notice {*/
/*  margin: 0;*/
/*  padding: 10px 15px;*/
/*  background-color: #2C363F;*/
/*  color: #ffffff;*/
/*  margin-bottom: 10px;*/
/*}*/

header {
  padding: 5px 20px;
  font-weight: 300;
  ul {
    li {
      margin-left: 50px;

      button {
        font-weight: 300 !important;
        margin-top: 3px;
      }
    }
  }
}

.section-light {
  color: #333333;
}






.wavy {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: auto;
}

$chain-icon-size: 90px;



footer {
  background: #ffffff;
  border-top: 1px solid #ccc;
  padding-top: 20px;
  padding-bottom: 50px;
  font-weight: 300;
  font-size: 14px;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  h5 {
    font-size: 16px;
    margin-bottom: 15px;
  }

  @media (min-width: 768px) {
    padding-top: 50px;
  }

}

.fa-twitter {
  color: #00acee;
}

.fa-telegram {
  color: #2FA3D9;
}




.btn {
  --bs-btn-padding-y: 0.65rem;
}

.navbar-nav {
  --bs-nav-link-padding-y: 0.75rem;
}


.section-full {
  min-height: 80vh;
}

.fr {
  float: right;
}

.copy-button {
  margin-top: 12px;
  cursor: pointer;
}

/* Define the pulsing animation */
@keyframes pulse {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
}

.pulsing-element {
  animation: pulse 2s ease-in-out infinite;
}

/* Set different random delays for multiple elements */
.pulsing-element:nth-child(1) {
  animation-delay: 0s;
}

.pulsing-element:nth-child(2) {
  animation-delay: 0.3s;
}

.pulsing-element:nth-child(3) {
  animation-delay: 0.6s;
}

.pulsing-element:nth-child(4) {
  animation-delay: 0.9s;
}

.pulsing-element:nth-child(5) {
  animation-delay: 1.2s;
}

/* Continue this pattern for additional elements */



.pool-container {
  background: #F6F3FC;
  border: 1px solid #ccc;
  //height: 100px;
  padding: 25px 20px;
  margin-bottom: 20px;
}

.pools-header {
  padding: 10px;
  //margin-top: 20px;
}


.modal-header {
  border-bottom: none;
}

.modal-footer {
  padding-top: 0px;
  border-top: none;
}

.modal-body {
  padding-top: 0px;
  padding-bottom: 0px;
  font-size: 14px;
}

.toast {
  background-color: $primary;
  color: #ffffff;
}